import GamesList from './games/GamesList';

const MainPage = () => {
  return (
    <div>
      <GamesList />
    </div>
  )
}

export default MainPage