const menus = [
  {
    to: '/',
    text: 'Последние игры'
  },
  {
    to: '/ratings-table',
    text: 'Таблица'
  },
  {
    to: '/boardgames',
    text: 'Настолки клуба'
  },
  {
    to: '/candy-crush',
    text: 'Candiez'
  },
]

export default menus;